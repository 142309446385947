import React from 'react'
import '../Assets/Styles/Components/Phone.css'

const Phone = () => {
    return (
        <>
            <a onClick={()=>{window.dataLayer.push({'event': 'telefono_boton'})}} name="Enlace de redirección al número telefonico - Plomeria H&D" href="tel:3225228487">
                <section className="phone__container">
                    <i className="fas fa-phone"></i>
                </section>
            </a>
        </>
    )
}

export default Phone