import React from 'react'
import '../Assets/Styles/Components/Button.css'

const Button = () => {
    return (
        <>
            <a onClick={()=>{window.dataLayer.push({'event': 'boton_whatsapp'})}} name="Enlace de redirección al WhatsApp - Plomeria H&D" target="_blank" rel='noreferrer' href="https://wa.link/50ta4c">
                <section className="whatsapp__container">
                    <i className="fab fa-whatsapp"></i>
                </section>
            </a>
        </>
    )
}

export default Button