import { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Layout from '../Components/Layout/layout'
import Loader from '../Components/Loader';
import pages from '../Data/pages'
import '../Assets/Styles/App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Layout>
        <Switch>
          <Suspense fallback={<Loader />}>
            {pages.map((pg) => (
              <Route key={pg.path} exact path={pg.path} component={pg.component}/>
            ))}
          </Suspense>
        </Switch>
      </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
