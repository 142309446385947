import React from 'react'
import '../Assets/Styles/Components/Loader.css'

const Loader = () => {
    return (
        <>
            <section className="loader__container">
                <div className="lds-facebook">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </section>
        </>
    )
}

export default Loader