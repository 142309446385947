import React from 'react'
import Button from '../Button.jsx'
import Phone from '../Phone.jsx'

const layout = ({ children }) => {
    return (
        <>
            {children}
            <Button />
            <Phone />
        </>
    )
}

export default layout
