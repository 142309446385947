import { lazy } from 'react'

const Inicio = lazy(() => import('../Pages/Inicio.jsx'))

const pages = [
    {
        path: '/',
        component: Inicio,
    }
]

export default pages